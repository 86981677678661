import React from 'react'
import { Button, Flex, Text, Modal } from '@robustswap-libs/uikit'
import { useMedia } from 'react-use'
import useGetPriceData from 'hooks/useGetPriceData'
import { Container, Content } from './styleds'

type MiningModalProps = {
  onDismiss?: () => void
  title?: string
  claimbalance?: string,
  poolbalance1?: string,
  poolbalance2?: string,
  minimumbalance?: string,
  onClaim?: () => void
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const MiningModal = ({ onDismiss = defaultOnDismiss, title, claimbalance, poolbalance1, poolbalance2, minimumbalance, onClaim }: MiningModalProps) => {
  const isMobile = useMedia('(max-width: 970px)')
  const panel = document.getElementsByClassName("LeftSideBar")[0]
  const marginLeft = isMobile ? 0 : panel?.clientWidth

  const cakePriceUsd = useGetPriceData('rbs').toFixed(2)
  const claimPriceUsd = Number(claimbalance) * Number(cakePriceUsd)
  const poolPriceUsd1 = Number(poolbalance1) * Number(cakePriceUsd)
  const poolPriceUsd2 = Number(poolbalance2) * Number(cakePriceUsd)


  return (
    <Modal title={title}
      onDismiss={onDismiss}
      margin={`0px 0px 0px ${marginLeft}px`}
      background="linear-gradient(90.04deg, #0C0720 0.04%, #291A83 99.97%)"
      headerPadding="20px">
      <Content>
        <Text color="white" fontSize="12px">Earn free RBS tokens every time you trade between BNB-RBS & BNB-RBS.</Text>
        <Container>
          <Text color="white" fontSize="12px">Your Earnings</Text>
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="#B347EE" fontSize="20px" style={{ fontWeight: 800 }}>{claimbalance} RBS</Text>
            <Button variant="subtle" style={{ maxHeight: '32px' }} onClick={onClaim}>Claim</Button>
          </Flex>
          <Text color="white" fontSize="10px">~${claimPriceUsd}</Text>
        </Container>
        <Container>
          <Text color="white" fontSize="12px">Pool Balance: (BNB-RBS)</Text>
          <Text color="#B347EE" fontSize="20px" style={{ fontWeight: 800 }}>{poolbalance1} RBS</Text>
          <Text color="white" fontSize="10px">~${poolPriceUsd1}</Text>
        </Container>
        <Container>
          <Text color="white" fontSize="12px">Pool Balance: (BNB-RBT)</Text>
          <Text color="#B347EE" fontSize="20px" style={{ fontWeight: 800 }}>{poolbalance2} RBS</Text>
          <Text color="white" fontSize="10px">~${poolPriceUsd2}</Text>
        </Container>
        <Text color="white" fontSize="10px" style={{ marginTop: '16px' }}>* Minimum required transaction amount is {minimumbalance} BNB. Transactions of 1 BNB or above will receive the total pool balance.</Text>
      </Content>
    </Modal>
  )
}

export default MiningModal
