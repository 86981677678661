import React, { createContext } from 'react'
import { allLanguages } from 'constants/localisation/languageCodes'

export interface LanguageObject {
  code: string
  language: string
}
interface LanguageState {
  selectedLanguage: LanguageObject
  setSelectedLanguage: (langObject: LanguageObject) => void
  translatedLanguage: LanguageObject
  setTranslatedLanguage: React.Dispatch<React.SetStateAction<LanguageObject>>
}

const currentLanguageState = allLanguages.filter(item => item.code === localStorage.getItem('robustSwapLanguage'))[0]

const defaultLanguageState: LanguageState = {
  selectedLanguage: { code: currentLanguageState?.code || 'en', language: currentLanguageState?.language || 'English' },
  setSelectedLanguage: (): void => undefined,
  translatedLanguage: { code: currentLanguageState?.code || 'en', language: currentLanguageState?.language || 'English' },
  setTranslatedLanguage: (): void => undefined,
}

export const LanguageContext = createContext(defaultLanguageState as LanguageState)
