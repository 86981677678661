import { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useTokenContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import useGetTokenData from './useGetTokenData'

const rbsAddress = "0x95336aC5f7E840e7716781313e1607F7C9D6BE25"
const rbsPairAddress = "0x10E728980e63b98CEe27d7fC4305cd3FaA4C1bC1"
const rbtAddress = "0x891e4554227385c5c740f9b483e935e3cbc29f01"
const rbtPairAddress = "0x8B1a0BFBAe06368bB81dc744349461a969E053c0"
const bnbAddress = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
const busdAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"

const useTokenPriceVsQuote = (symbol: string) => {
  let cakeAddress = ""
  let quoteAddress = ""
  let param = ""
  switch (symbol) {
    case 'rbs':
      cakeAddress = rbsAddress
      quoteAddress = busdAddress
      param = rbsPairAddress
      break;
    case 'rbt':
      cakeAddress = rbtAddress
      quoteAddress = bnbAddress
      param = rbtPairAddress
      break;
  }
  const contract1 = useTokenContract(cakeAddress, false)
  const contract2 = useTokenContract(quoteAddress, false)
  const tokenBalanceLP = useSingleCallResult(contract1, 'balanceOf', [param ?? undefined])?.result?.[0]
  const quoteTokenBalanceLP = useSingleCallResult(contract2, 'balanceOf', [param ?? undefined])?.result?.[0]
  const bnbPrice = useGetTokenData()
  return useMemo(() => {
    if (symbol === 'bnb' && bnbPrice) {
      return new BigNumber(bnbPrice)
    }
    if (tokenBalanceLP === undefined) return ''
    if (quoteTokenBalanceLP === undefined) return ''
    return new BigNumber(quoteTokenBalanceLP.toString()).div(new BigNumber(tokenBalanceLP.toString()))
  }, [tokenBalanceLP, quoteTokenBalanceLP, bnbPrice, symbol])
}

export default useTokenPriceVsQuote