import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Pair } from '@pancakeswap/sdk'
import { Button, CardBody, Text } from '@robustswap-libs/uikit'
import { Link } from 'react-router-dom'
import CardNav from 'components/CardNav'
import FullPositionCard from 'components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { TYPE } from 'components/Shared'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'

import { useActiveWeb3React } from 'hooks'
import { usePairs } from 'data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { Dots } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import { TranslateString } from 'utils/translateTextHelpers'
import PageHeader from 'components/PageHeader'
import AppBody from '../AppBody'

const { body: Body } = TYPE

export default function Pool() {
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading = fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => {
    return Boolean(v2Pair)
  })

  const Divider = styled.div`
    width: calc(100% - 48px);
    height: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    border-top: 1px solid #151745;
  `

  return (
    <>
      <CardNav activeIndex={1} />
      <AppBody>
        <PageHeader title={TranslateString(286, "Your Liquidity")} description={TranslateString(288, "Remove liquidity to receive tokens back")} />
        <Divider />
        <AutoColumn>
          <CardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
              {!account ? (
                <LightCard padding="40px">
                  <Body textAlign="center">
                    {TranslateString(290, "Connect to a wallet to view your liquidity")}
                  </Body>
                </LightCard>
              ) : v2IsLoading ? (
                <LightCard padding="40px">
                  <Body textAlign="center">
                    <Dots>{TranslateString(299, "Loading")}</Dots>
                  </Body>
                </LightCard>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <>
                  {allV2PairsWithLiquidity.map((v2Pair) => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                </>
              ) : (
                <LightCard padding="40px">
                  <Body textAlign="center">
                    <TranslatedText translationId={104}>{TranslateString(300, "No liquidity found")}</TranslatedText>
                  </Body>
                </LightCard>
              )}

              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                  {TranslateString(106, "Don't see a pool you joined?")}{' '}
                </Text>
              </div>
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Button as={Link} to="/find" variant="success" style={{ maxWidth: 230, height: 32, background: 'transparent', border: '1px solid #5F47EE', color: '#5F47EE', fontWeight: 600 }}>
                  {TranslateString(295, "Find other LP tokens")}
                </Button>
              </div>
              <Divider style={{ margin: '0px', width: '100%', marginBottom: 16 }} />
              <Button variant="subtle" id="join-pool-button" as={Link} to="/add/ETH" style={{ width: '100%' }}>
                <TranslatedText translationId={296}>ADD LIQUIDITY</TranslatedText>
              </Button>
            </AutoColumn>
          </CardBody>
        </AutoColumn>
      </AppBody>
    </>
  )
}
